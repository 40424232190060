import Combobox from '@github/combobox-nav'
// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'

observe('.js-role-fgp-input', {
  constructor: HTMLInputElement,
  add(input) {
    input.addEventListener('focus', showFGPList)
    input.addEventListener('blur', hideFGPList)
  },
  remove(input) {
    input.removeEventListener('focus', showFGPList)
    input.removeEventListener('blur', hideFGPList)
  },
})

observe('#js-fgp-list-items', {
  constructor: HTMLElement,
  add(list) {
    list.addEventListener('mousedown', keepFGPListOpen)
    // cache the FGP metadata once the visible elements have loaded
    fillFGPCache()
  },
  remove(list) {
    list.removeEventListener('mousedown', keepFGPListOpen)
  },
})

// take action upon modification of selected FGPs
on('change', '.js-fgp-list-item-checkbox', (event: Event) => {
  const fgpCheckbox = event.target
  if (!(fgpCheckbox instanceof HTMLInputElement)) return

  const fgp = fgpCheckbox.value || ''

  fgpCheckbox.checked ? addToSummary(fgp) : removeFromSummary(fgp)
  fgpCheckbox.setAttribute('aria-checked', fgpCheckbox.checked.toString())

  // let the user continue typing a filter
  getFGPInput().focus()

  // after selection, keep the current list item highlighted for a11y purposes
  const fgpListItem = fgpCheckbox.closest('.js-fgp-list-item')
  fgpListItem && fgpListItem.setAttribute('aria-selected', 'true')
})

// install dropdown combobox to input element.
// in our case, the FGP list and the <filter-input> respectively
observe('.js-role-fgp-input', {
  constructor: HTMLInputElement,
  initialize(input) {
    const combobox = new Combobox(getFGPInput(), getFGPList())
    input.addEventListener('focusin', () => {
      combobox.start()
    })

    input.addEventListener('focusout:delay', () => {
      combobox.stop()
    })
  },
})

const addToSummary = (fgp: string) => {
  const metadata = metadataFor(fgp)
  if (!metadata) return

  const listItem = createFGPItem(metadata)

  const category = metadata.category
  const categoryListElement = getFGPCategoryList(category)
  categoryListElement.appendChild(listItem)

  getFGPSummary().hidden = false
  getFGPCategoryTitle(category).hidden = false
  categoryListElement.hidden = false

  roundFirstCategoryHeading()
}

const removeFromSummary = (fgp: string) => {
  const metadata = metadataFor(fgp)
  if (!metadata) return

  const listItem = document.querySelector<HTMLElement>(`#fgp-item-${fgp}`)!

  const category = metadata.category
  const categoryList = getFGPCategoryList(category)

  listItem.remove()

  if (hideFGPCategory(categoryList)) {
    categoryList.hidden = true
    getFGPCategoryTitle(category).hidden = true
  }

  const summaryElement = getFGPSummary()
  if (hideFGPSummary(summaryElement)) {
    summaryElement.hidden = true
  } else {
    roundFirstCategoryHeading()
  }
}

// Set the rounded-0 class for every category heading except the first visible one
const roundFirstCategoryHeading = () => {
  const firstVisibleCategory = document.querySelector('.js-fgp-category-title:not([hidden])')
  if (!firstVisibleCategory) return

  const categoryHeadings = document.querySelectorAll('.js-fgp-category-title')

  for (const categoryHeading of categoryHeadings) {
    categoryHeading.classList.add('rounded-0')
  }
  firstVisibleCategory.classList.remove('rounded-0')
}

const uncheck = (fgp: string) => {
  const fgpCheckbox = getCheckboxFor(fgp)!

  fgpCheckbox.checked = false
}

const removeFGPItem = (event: Event) => {
  const target = event.target
  if (!(target instanceof Element)) return

  const listItemElement = target.closest('.js-added-fgp-item')!
  const fgp = listItemElement.getAttribute('data-fgp')!

  removeFromSummary(fgp)
  uncheck(fgp)
}

on('click', '.js-added-fgp-btn', removeFGPItem)

// stylized <li> with all the FGP metadata to be added to the summary
const createFGPItem = (fgp: FGPMetadata): DocumentFragment => {
  const listItemFragment = getItemTemplate().content.cloneNode(true) as DocumentFragment

  const listItem = listItemFragment.querySelector<HTMLElement>('.js-added-fgp-item')!

  const descriptionElement = listItem.querySelector<HTMLElement>('.js-added-fgp-description')!
  descriptionElement.textContent = fgp.description

  listItem.setAttribute('id', `fgp-item-${fgp.label}`)
  listItem.setAttribute('data-fgp', fgp.label)

  return listItemFragment
}

// the <filter-input> element
const getFGPInput = () => document.querySelector<HTMLInputElement>('.js-role-fgp-input')!

// the dropdown associated with the FGP input element
const getFGPList = () => document.querySelector<HTMLElement>('#js-fgp-list-items')!

// the persistent summary of available and implicit FGPs
const getFGPSummary = () => document.querySelector<HTMLElement>('#js-fgp-summary')!

const getItemTemplate = () => {
  return getFGPSummary().querySelector<HTMLTemplateElement>('#js-fgp-list-item-template')!
}

// the title element for a given FGP category
const getFGPCategoryTitle = (category: string) => {
  return document.querySelector<HTMLElement>(`.js-fgp-category-title[data-fgp-category="${category}"]`)!
}

// the <ul> element for a given FGP category
const getFGPCategoryList = (category: string) => {
  return document.querySelector<HTMLElement>(`.js-fgp-category-list[data-fgp-category="${category}"]`)!
}

// find the checkbox input element corresponding to the FGP
const getCheckboxFor = (fgp: string): HTMLInputElement | null => {
  return getFGPList().querySelector<HTMLInputElement>(`.js-fgp-list-item-checkbox[value="${fgp}"]`)
}

const showFGPList = () => {
  getFGPList().hidden = false
  getFGPInput().setAttribute('aria-expanded', 'true')
}

let keepDropdownOpen = false
const hideFGPList = () => {
  if (keepDropdownOpen) {
    keepDropdownOpen = false
    return
  }

  getFGPList().hidden = true
  getFGPInput().setAttribute('aria-expanded', 'false')
}

const keepFGPListOpen = () => (keepDropdownOpen = true)

// hide the summary when there are no visible FGPs for the custom role
const hideFGPSummary = (summary: HTMLElement): boolean => {
  const summaryBox = summary.querySelector<HTMLElement>('.js-fgp-category-box')!

  return !summaryBox.querySelector('.js-fgp-category-element:not([hidden])')
}

// hide the category container when there are no FGPs shown for that category
const hideFGPCategory = (category: HTMLElement) => category.childElementCount === 0

const addAdditionalFGPToSummary = () => {
  const additionalFGPs = document.querySelectorAll<HTMLInputElement>(
    '.js-fgp-list-item-checkbox[data-is-additional-fgp]',
  )
  for (const fgpCheckbox of additionalFGPs) {
    fgpCheckbox.click()
  }
}

let fgpCollection: FGPCollection = {}
const isEmpty = (collection: FGPCollection) => Object.keys(collection).length === 0

const fillFGPCache = async () => {
  if (isEmpty(fgpCollection)) {
    const response = await fetch(fgpCollectionURL(), {
      headers: {'X-Requested-With': 'XMLHttpRequest'},
    })
    if (!response.ok) {
      return
    }
    fgpCollection = await response.json()
  }
  addAdditionalFGPToSummary()
}

const metadataFor = (fgp: string): FGPMetadata | undefined => fgpCollection[fgp]
const fgpCollectionURL = (): string => getFGPInput().getAttribute('data-fgp-metadata-url')!

// the expected payload format for RolesController#fgp_metadata
interface FGPCollection {
  [fgp: string]: FGPMetadata
}
interface FGPMetadata {
  label: string
  description: string
  category: string
}
