// The security settings index view is currently the home for configuring these
// features:
//
// - organization two-factor authentication requirement
//
// The two-factor requirement form can be in the following states:
//
// - requirement disabled
//   - actor does not have 2fa enabled
//     - checkbox is disabled and not checked
//     - save button is disabled
//   - actor has 2fa enabled
//     - no enforcement is needed
//       - checkbox is enabled and not checked
//       - save button submits form
//     - enforcement is needed
//       - checkbox is enabled
//       - save button opens modal
// - enforcing requirement
//  - checkbox is a spinner
//  - save button is disabled
// - requirement enabled
//   - checkbox is enabled and checked
//   - save button submits form

// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'

// Watch for form that needs confirmation before enforcing and capture form
// submit event. On submit, if the checkbox is checked cancel the form submit
// and open the confirmation modal instead.
on('change', '.js-two-factor-needs-enforced', function (event) {
  const currentTarget = event.currentTarget as HTMLInputElement
  /* eslint-disable-next-line github/no-d-none */
  document.querySelector<HTMLElement>('.js-confirm-2fa-modal')!.classList.toggle('d-none', !currentTarget.checked)
  /* eslint-disable-next-line github/no-d-none */
  document.querySelector<HTMLElement>('.js-2fa-save-button')!.classList.toggle('d-none', currentTarget.checked)
})
